import { REHYDRATE } from "redux-persist";
import {
  UPDATE_CURRENT_ABOUT_DATA,
  UPDATE_CURRENT_PRIVACY_DATA,
  UPDATE_CURRENT_TERMS_DATA,
} from "redux/actions/appConfiguration";
import { RESET } from "../actions";

const initialState = {
  terms: [],
  about: [],
  privacy: [],
};

const AppConfigurationReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_TERMS_DATA:
      const terms = action.payload || [];
      const termsLanguages = [];
      terms.forEach((item) => termsLanguages.push(item.languageId));

      return {
        ...state,
        terms,
        termsLanguages,
      };

    case UPDATE_CURRENT_ABOUT_DATA:
      const about = action.payload || [];
      const aboutLanguages = [];
      about.forEach((item) => aboutLanguages.push(item.languageId));

      return {
        ...state,
        about,
        aboutLanguages,
      };

    case UPDATE_CURRENT_PRIVACY_DATA:
      const privacy = action.payload || [];
      const privacyLanguages = [];
      privacy.forEach((item) => privacyLanguages.push(item.languageId));

      return {
        ...state,
        privacy,
        privacyLanguages,
      };

    case REHYDRATE:
      return {
        ...initialState,
        ...((action.payload || {}).common || {}),
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default AppConfigurationReducer;
