import { ROLE_TYPES } from "shared/constants";
import {
  LiveHelp,
  Policy,
  Contacts,
  Info,
  Campaign,
  Newspaper,
  ViewCarousel,
  Toc,
  Language,
  Source,
} from "@mui/icons-material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GroupSharpIcon from "@mui/icons-material/GroupSharp";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

const CUSTOMER_FAQ = {
  href: "/customer/faq",
  icon: <LiveHelp fontSize="small" />,
  title: "FAQ's",
};

const CUSTOMER_TC = {
  href: "/customer/terms-conditions",
  icon: <GroupSharpIcon fontSize="small" />,
  title: "Terms & Conditions",
};

const CUSTOMER_PRIVACY = {
  href: "/customer/privacy-policy",
  icon: <Policy fontSize="small" />,
  title: "Privacy Policy",
};

const CUSTOMER_CONTACT = {
  href: "/customer/contact-us",
  icon: <Contacts fontSize="small" />,
  title: "Contact Us",
};

const CUSTOMER_ABOUT = {
  href: "/customer/about-us",
  icon: <Info fontSize="small" />,
  title: "About Us",
};

const CUSTOMER_APPLICATION_TAGS = {
  href: "/customer/application-tags",
  icon: <Language fontSize="small" />,
  title: "Application Tags",
};

const PROVIDER_FAQ = {
  href: "/provider/faq",
  icon: <LiveHelp fontSize="small" />,
  title: "FAQ's",
};

const PROVIDER_TC = {
  href: "/provider/terms-conditions",
  icon: <GroupSharpIcon fontSize="small" />,
  title: "Terms & Conditions",
};

const PROVIDER_PRIVACY = {
  href: "/provider/privacy-policy",
  icon: <Policy fontSize="small" />,
  title: "Privacy Policy",
};

const PROVIDER_CONTACT = {
  href: "/provider/contact-us",
  icon: <Contacts fontSize="small" />,
  title: "Contact Us",
};

const PROVIDER_ABOUT = {
  href: "/provider/about-us",
  icon: <Info fontSize="small" />,
  title: "About Us",
};

const PROVIDER_APPLICATION_TAGS = {
  href: "/provider/application-tags",
  icon: <Language fontSize="small" />,
  title: "Application Tags",
};

const CUSTOMER_ROUTES = [
  CUSTOMER_FAQ,
  CUSTOMER_TC,
  CUSTOMER_PRIVACY,
  CUSTOMER_CONTACT,
  CUSTOMER_ABOUT,
  CUSTOMER_APPLICATION_TAGS,
];

const PROVIDER_ROUTES = [
  PROVIDER_FAQ,
  PROVIDER_TC,
  PROVIDER_PRIVACY,
  PROVIDER_CONTACT,
  PROVIDER_ABOUT,
  PROVIDER_APPLICATION_TAGS,
];

const PROMOTION_ROUTES = [
  {
    href: "/broadcast",
    icon: <Campaign fontSize="small" />,
    title: "Broadcasts",
  },
  {
    href: "/template",
    icon: <Newspaper fontSize="small" />,
    title: "Communications Templates",
  },
  {
    href: "/coupons",
    icon: <LocalOfferIcon fontSize="small" />,
    title: "Coupons",
  },
  {
    href: "/banners",
    icon: <ViewCarousel fontSize="small" />,
    title: "Banners",
  },
  {
    href: "/communication-config",
    icon: <Source fontSize="small" />,
    title: "Communication Config",
  },
];

export const APP_CONFIG_ROUTES = [
  {
    icon: <AccountTreeIcon fontSize="small" />,
    title: "App Configuration",
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
    child: [
      {
        href: "/appconfig",
        icon: <Source fontSize="small" />,
        title: "Config",
      },
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Customer App",
        child: CUSTOMER_ROUTES,
      },
      {
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Provider App",
        child: PROVIDER_ROUTES,
      },
      {
        href: "/reasons",
        icon: <Toc fontSize="small" />,
        title: "Reasons",
      },
      {
        href: "/api-response-messages",
        icon: <AccountTreeIcon fontSize="small" />,
        title: "Api Response Messages",
      },
      {
        href: "/logger",
        icon: <AccountTreeIcon fontSize="small" />,
        title: "D Logger",
      },
    ],
  },
];

export const BROADCAST_ROUTES = [
  {
    icon: <AccountTreeIcon fontSize="small" />,
    title: "Promotions",
    child: PROMOTION_ROUTES,
    enabled: { [ROLE_TYPES["super-admin"].value]: true },
  },
];
