import { Button, Chip, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomField,
  getErrorKey,
  getErrorText,
  INPUT_TYPES,
} from "../CustomField";
import ModalWrapper from "../ModalWrapper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { fetchServices } from "utils/calls";
import {
  getDistrictsRequest,
  getPinCodesRequest,
  getStatesRequest,
} from "screens/Services/ServiceDetails/ServiceLocation/utils";
import moment from "moment";
import { getCategoryList } from "screens/Category/utils";

const FilterModal = ({
  filtersData,
  onChangeFilter,
  fields = [],
  closeModal,
  includeAllOption,
}) => {
  const [dropdownData, setDropdownData] = useState({});
  const [data, setData] = useState(filtersData || {});

  useEffect(() => {
    getCategoryList({
      onSuccess: (data) => {
        data = data.map(({ categoryId, categoryName }) => ({
          label: categoryName,
          value: categoryId,
        }));

        setDropdownData((_state) => ({
          ..._state,
          categories: includeAllOption
            ? [{ label: "All", value: "ALL" }, ...data]
            : data,
        }));
      },
    });
    fetchServices({
      includeAllOption,
      onSuccess: (services) =>
        setDropdownData((_state) => ({ ..._state, services })),
    });
    getStatesRequest({
      includeAllOption,
      onSuccess: (states) =>
        setDropdownData((_state) => ({ ..._state, states })),
    });
    filtersData.state &&
      filtersData.state !== "ALL" &&
      getDistrictsRequest({
        includeAllOption,
        state: filtersData.state,
        onSuccess: (districts) =>
          setDropdownData((_state) => ({ ..._state, districts })),
      });

    filtersData.district &&
      filtersData.district !== "ALL" &&
      getPinCodesRequest({
        state: data.state,
        district: data.district,
        onSuccess: (pincodes) =>
          setDropdownData((_state) => ({ ..._state, pincodes })),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData]);

  const onChange = ({ overRideValues = {}, value, _key }) => {
    setData((_state) => {
      if (_key === "serviceId" || _key === "service") {
        _state.serviceLabel = dropdownData.services.find(
          (item) => item.value === value
        ).label;
      }
      return { ..._state, ...overRideValues, [_key]: value };
    });

    if (_key === "categoryId" && value !== "ALL") {
      fetchServices({
        includeAllOption,
        categoryId: value,
        onSuccess: (services) =>
          setDropdownData((_state) => ({ ..._state, services })),
      });
    }

    if (_key === "state" && value !== "ALL") {
      getDistrictsRequest({
        includeAllOption,
        state: value,
        onSuccess: (districts) =>
          setDropdownData((_state) => ({ ..._state, districts })),
      });
    }
    if (_key === "district" && value !== "ALL") {
      getPinCodesRequest({
        includeAllOption,
        state: data.state,
        district: value,
        onSuccess: (pincodes) =>
          setDropdownData((_state) => ({ ..._state, pincodes })),
      });
    }
  };

  const onApply = () => onChangeFilter(data);
  const onClear = () => onChangeFilter({});

  return (
    <ModalWrapper
      sx={{ width: "50%" }}
      childSx={{
        minHeight: "10vh",
        maxHeight: "70vh",
        p: 2,
        overflow: "auto",
      }}
      footerButtons={[
        {
          title: "Clear Filters",
          onClick: onClear,
          variant: "secondary",
          sx: { mr: 2 },
        },
        { title: "Apply Filters", onClick: onApply },
      ]}
      title="Filters"
      closeModal={closeModal}
    >
      <Grid container rowSpacing={2} spacing={1}>
        {fields.map((item) => {
          const { dataKey, md, _key, dependentKey, hideForParentValue } = item;
          if (
            dependentKey &&
            (!data[dependentKey] ||
              (hideForParentValue && data[dependentKey] === hideForParentValue))
          ) {
            return null;
          }

          return (
            <Grid key={_key} item md={md || 6} sm={12} xs={12}>
              <CustomField
                item={{
                  isOptional: true,
                  extraData: dropdownData[dataKey] || [],
                  ...item,
                }}
                itemData={data}
                errorText={data[getErrorText(_key)]}
                error={data[getErrorKey(_key)]}
                value={data[_key]}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalWrapper>
  );
};

const CustomFilter = ({
  filtersData = {},
  onChange,
  fields = [],
  includeAllOption,
}) => {
  const [showModal, toggleModal] = useState(false);
  const closeModal = () => toggleModal(false);
  const onShow = () => toggleModal(true);

  const onChangeFilter = (...rest) => {
    closeModal();
    onChange(...rest);
  };

  if (!fields.length) {
    return null;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexWrap: "wrap", display: "flex", gap: 4 }}>
          {fields.map(({ inputType, deleteValues = {}, placeholder, _key }) => {
            if (!filtersData[_key]) {
              return null;
            }

            if (
              inputType === INPUT_TYPES.FILTER_OPTIONS &&
              filtersData[_key]?.length
            ) {
              return (
                <Chip
                  label={
                    placeholder +
                    ": " +
                    filtersData[_key].map(({ label }) => label).join(", ")
                  }
                  key={_key}
                  color="primary"
                  onClick={onShow}
                  onDelete={() => onChange({ ...filtersData, [_key]: "" })}
                />
              );
            }
            const prefix = placeholder + ": ";
            const label =
              inputType === INPUT_TYPES.DATE
                ? moment(new Date(filtersData[_key])).format("DD MMM YYYY")
                : filtersData[
                    _key === "serviceId" || _key === "service"
                      ? "serviceLabel"
                      : _key
                  ];

            return (
              <Chip
                label={prefix + label}
                key={_key}
                onClick={onShow}
                color="primary"
                onDelete={() =>
                  onChange({ ...filtersData, ...deleteValues, [_key]: "" })
                }
              />
            );
          })}
        </div>
        <Button
          onClick={onShow}
          variant="contained"
          startIcon={<FilterAltIcon />}
        >
          Filters
        </Button>
      </div>
      {showModal && (
        <FilterModal
          onChangeFilter={onChangeFilter}
          closeModal={closeModal}
          fields={fields}
          filtersData={filtersData}
          includeAllOption={includeAllOption}
        />
      )}
    </>
  );
};

export default CustomFilter;
