import Reasons from "screens/Services/ServiceDetails/Reasons";
import AddEditReasons from "screens/Services/ServiceDetails/Reasons/AddEditReasons";
import ApiResponseMessages from "screens/AppConfiguration/apiResponseMessages";
import ApiResponseMessagesByKeyword from "screens/AppConfiguration/apiResponseMessages/components/ApiResponseMessagesByKeyword";
import { QueryDetail } from "screens/AppConfiguration/contactUs/components/QueryDetail";
import ApplicationTags from "screens/AppConfiguration/applicationTags";
import Banners from "screens/Banners";
import {
  AddEditTemplate,
  BroadcastListing,
  CommunicationTemplatesListing,
  TriggerBroadcast,
} from "screens/Broadcast";
import ChargesList from "screens/Charges";
import ChargesDetails from "screens/Charges/ChargeDetails";
import Coupons from "screens/Coupons";
import { CustomerDetails } from "screens/Customer";
import CsTicketDetails from "screens/CustomerSupport/CsTicketDetails";
import CsTickets from "screens/CustomerSupport/CsTickets";
import UserSearch from "screens/CustomerSupport/UserSearch";
import PaymentDetail from "screens/CustomerSupport/components/PaymentDetail";
import PayOrders from "screens/PayOrders";
import CreatePayOrder from "screens/PayOrders/CreatePayOrder";
import PayOrderDetail from "screens/PayOrders/PayOrderDetail";
import ApplianceDetail from "screens/Services/ServiceDetails/SpareParts/ApplianceDetail";
import Config from "screens/AppConfiguration/Config";
import CommunicationConfig from "screens/CommunicationConfig";

const {
  TermsAndConditionsList,
  TermsAndConditionsVersionsList,
  ContactUs,
} = require("screens/AppConfiguration");
const { CategoryList } = require("screens/Category");
const { UpdateCategory } = require("screens/Category/UpdateCategory");
const { default: ChangePassword } = require("screens/ChangePassword");
const { Home } = require("screens/Home");
const { LanguagesList } = require("screens/Languages");
const { default: Profile } = require("screens/Profile");
const {
  EnrollmentQuestions,
  EnrollmentQuestionsDetail,
  ProviderList,
  ProviderDetails,
  ProviderCalendar,
} = require("screens/Provider");
const { ServicesList, ServiceDetails } = require("screens/Services");
const { default: Users } = require("screens/Users");
const { default: UserDetails } = require("screens/Users/UserDetails");
const { ROLE_TYPES, USER_PRIVILEGE_TYPES } = require("shared/constants");
const { FAQ } = require("screens/AppConfiguration/faq");

const OPERATIONS = {
  [ROLE_TYPES["super-admin"].value]: true,
  [ROLE_TYPES["country-admin"].value]: true,
  [ROLE_TYPES["state-admin"].value]: true,
  [ROLE_TYPES["district-admin"].value]: true,
};

const SUPPORT = {
  [ROLE_TYPES["super-admin"].value]: true,
  [ROLE_TYPES["country-support-admin"].value]: true,
  // [ROLE_TYPES["state-support-admin"].value]: true,
  // [ROLE_TYPES["district-support-admin"].value]: true,
};

const getRoutesByPrivilege = ({
  userPrivilege,
  providerRoutes,
  serviceRoutes,
}) => {
  let routes = [];

  if (!userPrivilege) {
    return [...providerRoutes, ...serviceRoutes];
  }

  if (
    USER_PRIVILEGE_TYPES.providers.value === userPrivilege ||
    USER_PRIVILEGE_TYPES.both.value === userPrivilege
  ) {
    routes.push(...providerRoutes);
  }

  if (
    USER_PRIVILEGE_TYPES.services.value === userPrivilege ||
    USER_PRIVILEGE_TYPES.both.value === userPrivilege
  ) {
    routes.push(...serviceRoutes);
  }

  return routes;
};

export const getProtectedRoutes = ({ userRole, userPrivilege }) => {
  const BookingDetails = require("screens/Bookings/BookingDetails").default;
  const ServicesNearMe = require("screens/Services/ServicesNearMe").default;
  const Bookings = require("screens/Bookings").default;
  const Jobs = require("screens/Jobs").default;

  const routes = [
    { path: "/", Component: Home },
    { path: "/change-password", Component: ChangePassword },
    { path: "/profile", Component: Profile },
  ];
  if (userRole === ROLE_TYPES["super-admin"].value) {
    routes.push(
      { path: "/languages", Component: LanguagesList },
      { path: "/users/:id", Component: UserDetails },
      { path: "/add-users", Component: UserDetails },
      { path: "/users", Component: Users },
      { path: "/categories", Component: CategoryList },
      { path: "/category/:id", Component: UpdateCategory },
      { path: "/services", Component: ServicesList },
      { path: "/charges", Component: ChargesList },
      { path: "/charges/:id", Component: ChargesDetails },
      { path: "/service-detail/:serviceId", Component: ServiceDetails },
      { path: "/create-service", Component: ServiceDetails },
      { path: "/enrollment-questions", Component: EnrollmentQuestions },
      {
        path: "/enrollment-questions-detail/:serviceId",
        Component: EnrollmentQuestionsDetail,
      },
      {
        path: "/:userType/terms-conditions",
        Component: TermsAndConditionsVersionsList,
      },
      { path: "/:userType/faq", Component: FAQ },
      {
        path: "/:userType/privacy-policy",
        Component: TermsAndConditionsVersionsList,
      },
      {
        path: "/:userType/about-us",
        Component: TermsAndConditionsVersionsList,
      },
      {
        path: "/:userType/terms-conditions/:version",
        Component: TermsAndConditionsList,
      },
      {
        path: "/:userType/privacy-policy/:version",
        Component: TermsAndConditionsList,
      },
      {
        path: "/:userType/about-us/:version",
        Component: TermsAndConditionsList,
      },
      {
        path: "/:userType/contact-us",
        Component: ContactUs,
      },
      {
        path: "/:userType/query-detail",
        Component: QueryDetail,
      },
      {
        path: "/api-response-messages",
        Component: ApiResponseMessages,
      },
      {
        path: "/api-response-messages/:keyword",
        Component: ApiResponseMessagesByKeyword,
      },
      {
        path: "/template/:type/:id",
        Component: AddEditTemplate,
      },
      {
        path: "/template/:type",
        Component: AddEditTemplate,
      },
      {
        path: "/broadcast",
        Component: BroadcastListing,
      },
      {
        path: "/template",
        Component: CommunicationTemplatesListing,
      },
      {
        path: "/broadcast/:type/:id",
        Component: TriggerBroadcast,
      },
      {
        path: "/broadcast/:type",
        Component: TriggerBroadcast,
      },
      {
        path: "/coupons",
        Component: Coupons,
      },
      {
        path: "/banners",
        Component: Banners,
      },
      {
        path: "/communication-config",
        Component: CommunicationConfig,
      },
      {
        path: "/reasons",
        Component: Reasons,
      },
      {
        path: "/reasons/:type/:id",
        Component: AddEditReasons,
      },
      {
        path: "/reasons/:type",
        Component: AddEditReasons,
      },
      {
        path: "/appconfig",
        Component: Config,
      },
      {
        path: "/:userType/payOrders",
        Component: PayOrders,
      },
      {
        path: "/:userType/payOrders/:id",
        Component: PayOrderDetail,
      },
      {
        path: "/:userType/payOrder/create",
        Component: CreatePayOrder,
      },
      {
        path: "/:userType/account/search",
        Component: UserSearch,
      },
      {
        path: "/payment/:id",
        Component: PaymentDetail,
      },
      {
        path: "/:userType/application-tags",
        Component: ApplicationTags,
      },
      {
        path: "/service-detail/:serviceId/appliance-detail/:applianceId",
        Component: ApplianceDetail,
      }
    );
  }

  if (OPERATIONS[userRole]) {
    routes.push(
      ...getRoutesByPrivilege({
        userPrivilege,
        providerRoutes: [
          { path: "/providers-list", Component: ProviderList },
          { path: "/provider/calendar/:id", Component: ProviderCalendar },
          { path: "/providers-list/:listType", Component: ProviderList },
          { path: "/provider-details/:id", Component: ProviderDetails },
          { path: "/jobs", Component: Jobs },
          { path: "/jobs/:jobId", Component: BookingDetails },
        ],
        serviceRoutes: [
          { path: "/bookings", Component: Bookings },
          { path: "/bookings/:bookingId", Component: BookingDetails },
          { path: "/services-near-me", Component: ServicesNearMe },
          // { path: "/search-pincode", Component: EnabledPinCodes },
        ],
      })
    );
  }

  if (SUPPORT[userRole]) {
    routes.push(
      ...getRoutesByPrivilege({
        userPrivilege,
        providerRoutes: [
          {
            path: "/:userType/support/search",
            Component: UserSearch,
          },
          {
            path: "/:userType/support/tickets",
            Component: CsTickets,
          },
          {
            path: "/support/ticket/:id",
            Component: CsTicketDetails,
          },
        ],
        serviceRoutes: [
          {
            path: "/:userType/support/search",
            Component: UserSearch,
          },
          {
            path: "/:userType/support/tickets",
            Component: CsTickets,
          },
          {
            path: "/support/ticket/:id",
            Component: CsTicketDetails,
          },
        ],
      })
    );
  }

  if (OPERATIONS[userRole] || SUPPORT[userRole]) {
    routes.push(
      ...getRoutesByPrivilege({
        userPrivilege,
        providerRoutes: [
          { path: "/provider-details/:id", Component: ProviderDetails },
          { path: "/jobs/:jobId", Component: BookingDetails },
        ],
        serviceRoutes: [
          { path: "/bookings/:bookingId", Component: BookingDetails },
          { path: "/customer-details/:id", Component: CustomerDetails },
        ],
      })
    );
  }
  return routes;
};
