import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { INPUT_TYPES } from "components";
import moment from "moment";
import { customSortComparatorForNums } from "utils";
import amountFormat from "utils/amountFormat";
import { JOB_FREQUENCY_TYPE } from "./JobDurationSelector";

export const CHARGES_UPDATED_FOR = {
  ESTIMATE: "Estimate",
  PAYMENT_INITIATE: "PaymentInitiate",
};

export const ESTIMATE_STATUSES = {
  SUBMIT: "submit",
  ACCEPT: "accept",
};

export const BOOKING_STATUS = {
  initiated: { value: "initiated", label: "Initiated" },
  booked: { value: "booked", label: "Booked" },
  jobAssigned: { value: "jobAssigned", label: "Job Assigned" },
  cancelled: { value: "cancelled", label: "Cancelled" },
  jobStart: { value: "jobStart", label: "Job Started" },
  jobRejected: { value: "jobRejected", label: "Job Rejected" },
  jobEnded: { value: "jobEnd", label: "Job Ended" },
  paymentPending: { value: "paymentPending", label: "Payment Pending" },
  paymentDone: { value: "paymentDone", label: "Payment Done" },
  bookingCompleted: { value: "bookingCompleted", label: "Booking Completed" },
};

// TODO - show payments in booking / job detail
// TODO - reverse actions

export const BOOKING_DETAIL_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "bookingId",
    md: 3,
    placeholder: "Booking ID",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "service",
    md: 3,
    placeholder: "Service",
  },
  {
    inputType: INPUT_TYPES.DATE_TIME,
    _key: "bookingTime",
    md: 3,
    placeholder: "Booking At",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "status",
    md: 3,
    placeholder: "Booking Status",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobId",
    md: 3,
    placeholder: "Job ID",
  },
  {
    inputType: INPUT_TYPES.DATE_TIME,
    _key: "jobScheduledTime",
    md: 3,
    placeholder: "Job Scheduled Time",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "jobScheduledDate",
    md: 3,
    placeholder: "Job Scheduled Date",
  },
  {
    inputType: INPUT_TYPES.DATE_TIME,
    _key: "endTime",
    md: 3,
    placeholder: "End Time",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "endDate",
    md: 3,
    placeholder: "End Date",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobStatus",
    md: 3,
    placeholder: "Job Status",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "balAmount",
    md: 3,
    placeholder: "Balance Amount",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "totalAmount",
    md: 3,
    placeholder: "Total Amount",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobFrequency",
    md: 3,
    placeholder: "Job Frequency",
    renderField: ({ jobFrequency }) =>
      Object.values(JOB_FREQUENCY_TYPE).find(
        (item) => item.value === jobFrequency
      )?.label,
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "jobFrequencyCounter",
    md: 2.5,
    placeholder: "Job Frequency Counter",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "serviceFromDate",
    md: 2.1,
    placeholder: "Service From Date",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "serviceUptoDate",
    md: 2.1,
    placeholder: "Service Upto Date",
  },
];

export const CUSTOMER_DETAIL_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "name",
    placeholder: "Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "phoneNumber",
    placeholder: "Phone Number",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "state",
    placeholder: "State",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "district",
    placeholder: "District",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "pincode",
    placeholder: "Pincode",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "address",
    placeholder: "Address",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "addressline2",
    placeholder: "Address Line 2",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "landmark",
    placeholder: "Landmark",
  },
];

export const PROVIDER_DETAIL_FIELDS = [
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "providerId",
    placeholder: "Provider ID",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "firstName",
    placeholder: "First Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "lastName",
    placeholder: "Last Name",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "phoneNumber",
    placeholder: "Phone Number",
  },
];

export const BOOKING_FILTERS = [
  {
    inputType: INPUT_TYPES.FILTER_OPTIONS,
    _key: "status",
    md: 12,
    placeholder: "Status",
    extraData: Object.values(BOOKING_STATUS),
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "serviceId",
    placeholder: "Service",
    dataKey: "services",
    deleteValues: { serviceLabel: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "state",
    placeholder: "State",
    dataKey: "states",
    overRideValues: { district: "", pincode: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "district",
    placeholder: "District",
    dataKey: "districts",
    dependentKey: "state",
    overRideValues: { pincode: "" },
  },
  {
    inputType: INPUT_TYPES.DROPDOWN,
    _key: "pincode",
    placeholder: "PIN Code",
    dataKey: "pincodes",
    dependentKey: "district",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "phoneNumber",
    placeholder: "Mobile",
  },
  {
    inputType: INPUT_TYPES.INPUT,
    _key: "bookingId",
    placeholder: "Booking ID",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "bookingFromDate",
    placeholder: "Booking From Date",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "bookingToDate",
    placeholder: "Booking To Date",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "scheduledFromDate",
    placeholder: "Scheduled From Date",
  },
  {
    inputType: INPUT_TYPES.DATE,
    _key: "scheduledToDate",
    placeholder: "Scheduled To Date",
  },
];

export const getBookingsListColumns = ({ onViewPress }) => [
  {
    field: "bookingId",
    headerName: "Id",
    width: 60,
    sortable: false,
    sortComparator: customSortComparatorForNums,
    renderCell: ({ row }) => row.bookingId || "-",
  },
  {
    field: "bookingTime",
    headerName: "Booked At",
    renderCell: ({ row }) =>
      row.bookingTime
        ? moment(new Date(row.bookingTime)).format("DD MMM YYYY, H:mm")
        : "-",
  },
  {
    field: "customerName",
    headerName: "Booked by",
    width: 200,
    renderCell: ({ row }) => row.customerName || "-",
  },
  {
    field: "phoneNumber",
    headerName: "Customer Number",
    width: 200,
    renderCell: ({ row }) =>
      (row.countryCode ? row.countryCode + "-" : "") + (row.phoneNumber || ""),
  },
  {
    field: "service",
    headerName: "Service",
    width: 80,
    renderCell: ({ row }) => row.service?.name || "-",
  },
  {
    field: "totalAmount",
    headerName: "Amount",
    width: 80,
    renderCell: ({ row }) =>
      row.totalAmount ? "₹" + amountFormat.format(row.totalAmount) : "-",
  },
  {
    field: "jobScheduledTime",
    headerName: "Scheduled For",
    width: 150,
    renderCell: ({ row }) =>
      row.jobScheduledTime
        ? moment(new Date(row.jobScheduledTime))
            .utc()
            .format("DD MMM YYYY, hh:mm A")
        : "-",
  },
  {
    field: "status",
    headerName: "Booking Status",
    width: 80,
  },
  {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: ({ row }) => {
      const onView = (e) => {
        e.stopPropagation();
        onViewPress(row);
      };

      return (
        <>
          <IconButton onClick={onView}>
            <Visibility color="primary" />
          </IconButton>
        </>
      );
    },
  },
];
