import { useSelector } from "react-redux";

export const useGetLanguageData = ({ data }) => {
  const allLanguages = useSelector(({ common }) => common.allLanguages) || [];

  const availableLanguages = allLanguages
    .filter((item) =>
      data.find((_node) => +_node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  const unavailableLanguages = allLanguages
    .filter(
      (item) => !data.find((_node) => +_node.languageId === item.languageId)
    )
    .map((lang) => ({
      ...lang,
      label: lang.languageName,
      value: lang.languageId,
    }));

  if (!availableLanguages.length) {
    const defaultLang = allLanguages.find((lang) => !!lang.isActive) || {};
    availableLanguages.push({
      ...defaultLang,
      label: defaultLang.languageName,
      value: defaultLang.languageId,
    });
  }

  return {
    availableLanguages,
    unavailableLanguages,
    defaultLanguageId: availableLanguages[0]?.languageId,
  };
};

export const useGetTermsData = () => {
  const terms =
    useSelector(({ appConfiguration }) => appConfiguration.terms) || [];

  const { availableLanguages, defaultLanguageId, unavailableLanguages } =
    useGetLanguageData({
      data: terms,
    });

  return { availableLanguages, defaultLanguageId, unavailableLanguages };
};

export const useGetPrivacyData = () => {
  const privacy =
    useSelector(({ appConfiguration }) => appConfiguration.privacy) || [];

  const { availableLanguages, unavailableLanguages, defaultLanguageId } =
    useGetLanguageData({
      data: privacy,
    });

  return { availableLanguages, unavailableLanguages, defaultLanguageId };
};

export const useGetAboutData = () => {
  const about =
    useSelector(({ appConfiguration }) => appConfiguration.about) || [];

  const { availableLanguages, defaultLanguageId, unavailableLanguages } =
    useGetLanguageData({
      data: about,
    });

  return { availableLanguages, defaultLanguageId, unavailableLanguages };
};
