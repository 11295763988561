import { Card } from "@mui/material";
import { ConfirmationModal } from "components/ConfirmationModal";
import Header from "components/HeaderV2";
import React, { useMemo, useState } from "react";
import AssignModal from "./AssignModal";
import {
  BOOKING_STATUS,
  CHARGES_UPDATED_FOR,
  ESTIMATE_STATUSES,
} from "./constants";
import JobOtpModal from "./JobOtpModal";
import { cancelBookingRequest, cancelJobRequest } from "./utils";
import { errorToast, queryString, successToast } from "utils";
import { getReasonsListRequest } from "screens/Services/ServiceDetails/Reasons/utils";
import EstimateModal from "./EstimateModal";
import { AddPaymentModal } from "screens/CustomerSupport/components/AddPaymentModal";
import { useNavigate } from "react-router-dom";
import SuspendBookingModal from "./components/SuspendBookingModal";
import { JOB_FREQUENCY_TYPE } from "./JobDurationSelector";
import { REASON_TYPES } from "screens/Services/ServiceDetails/Reasons/constants";

const RESCHEDULE = {
  [BOOKING_STATUS.jobAssigned.label]: true,
  [BOOKING_STATUS.booked.label]: true,
  [BOOKING_STATUS.jobStart.label]: true,
};

const ESTIMATE = {
  [BOOKING_STATUS.jobAssigned.label]: true,
  [BOOKING_STATUS.booked.label]: true,
  [BOOKING_STATUS.jobStart.label]: true,
};

const PAYMENT_INTIATE = {
  [BOOKING_STATUS.jobEnded.label]: true,
};

const PAYMENT_DONE = {
  [BOOKING_STATUS.paymentPending.label]: true,
};

const CANCEL = {
  [BOOKING_STATUS.booked.label]: true,
  [BOOKING_STATUS.jobAssigned.label]: true,
  [BOOKING_STATUS.jobStart.label]: true,
};

const getBookingActions = ({
  setConfirmationModalData,
  bookingStatus,
  jobFrequency,
  providerJobId,
  bookingId,
  serviceId,
  setEstimateModal,
  isBooking,
  fetchData,
  setAssignModalData,
  setOtpModal,
  setCreatePaymentModal,
  setSuspendModal,
  hideEstimateAction,
  estimateStatus,
  navigate,
}) => {
  const actions = [];
  if (!bookingStatus) {
    return actions;
  }

  if (isBooking && bookingStatus === BOOKING_STATUS.booked.label) {
    actions.push({
      title: "Assign",
      onClick: () => setAssignModalData({ type: "assign" }),
    });
  }
  if (!isBooking && bookingStatus === BOOKING_STATUS.jobStart.label) {
    actions.push({
      title: "End Job",
      onClick: () => setOtpModal("end"),
    });
  }

  // show only if isEstimateApplicable true
  if (isBooking && ESTIMATE[bookingStatus] && !hideEstimateAction) {
    actions.push({
      title: "Estimate",
      onClick: () =>
        setEstimateModal({ chargesUpdatedFor: CHARGES_UPDATED_FOR.ESTIMATE }),
      color: "secondary",
    });
  }
  if (isBooking && PAYMENT_INTIATE[bookingStatus]) {
    actions.push({
      title: "Payment Intiate",
      onClick: () =>
        setEstimateModal({
          chargesUpdatedFor: CHARGES_UPDATED_FOR.PAYMENT_INITIATE,
        }),
      color: "secondary",
    });
  }
  if (isBooking) {
    actions.push({
      title: "Payment",
      onClick: () => setCreatePaymentModal(true),
      color: "secondary",
    });
  }
  if (isBooking && bookingStatus !== BOOKING_STATUS.initiated.label) {
    actions.push({
      title: "Update Charges",
      onClick: () => setEstimateModal({ chargesUpdatedFor: "" }),
      color: "primary",
    });
  }
  if (!isBooking && bookingStatus === BOOKING_STATUS.jobAssigned.label) {
    actions.push({
      title: "Start Job",
      onClick: () => {
        if (estimateStatus === ESTIMATE_STATUSES.SUBMIT) {
          return errorToast("Estimate not accepted yet.");
        }

        setOtpModal("start");
      },
    });
  }
  if (RESCHEDULE[bookingStatus]) {
    actions.push({
      title: "Reschedule",
      onClick: () => setAssignModalData({ type: "reschedule" }),
    });
  }
  if (BOOKING_STATUS.jobAssigned.label === bookingStatus) {
    actions.push({
      title: "Re-Assign",
      onClick: () => setAssignModalData({ type: "jobAssigned" }),
    });
  }
  if (CANCEL[bookingStatus]) {
    actions.push({
      title: "Cancel",
      color: "error",
      onClick: () => {
        getReasonsListRequest({
          query: queryString({
            reasonApp: isBooking ? "customer" : "provider",
            type: REASON_TYPES.cancellation,
            languageId: 1,
            serviceId,
            isActive: true,
          }),
          onSuccess: (data) => {
            setConfirmationModalData({
              title: `Cancel ${isBooking ? "Booking" : "Job"}`,
              description: `Are you sure you want to cancel the ${
                isBooking ? "Booking" : "Job"
              }?`,
              reasons: data.map((reason) => ({
                label: reason.reason,
                value: reason._id,
              })),
              footerButtons: [
                {
                  title: "Not Now",
                  onClick: () => setConfirmationModalData(null),
                  sx: { mr: 2 },
                  color: "error",
                  variant: "outlined",
                },
                {
                  title: `Cancel ${isBooking ? "Booking" : "Job"}`,
                  color: "error",
                  isRequired: true,

                  onClick: ({ reason }) => {
                    const caller = isBooking
                      ? cancelBookingRequest
                      : cancelJobRequest;
                    caller({
                      onSuccess: () => {
                        successToast(
                          `${
                            isBooking ? "Booking" : "Job"
                          } cancelled successfully!`
                        );
                        fetchData();
                        setConfirmationModalData(null);
                      },
                      payload: isBooking
                        ? { reason, bookingId }
                        : { reason, jobId: providerJobId },
                    });
                  },
                },
              ],
            });
          },
        });
      },
    });
  }

  if (
    isBooking &&
    bookingStatus === BOOKING_STATUS.jobStart.label &&
    jobFrequency !== JOB_FREQUENCY_TYPE.oneTime.value
  ) {
    actions.push({
      title: "Suspend",
      color: "error",
      onClick: () => setSuspendModal(true),
    });
  }

  if (isBooking && !!providerJobId) {
    actions.push({
      title: "Go to job",
      onClick: () => navigate(`/jobs/${bookingId}`),
      variant: "outlined",
      color: "secondary",
    });
  }

  if (!isBooking) {
    actions.push({
      title: "Go to booking",
      onClick: () => navigate(`/bookings/${bookingId}`),
      variant: "outlined",
      color: "secondary",
    });
  }
  return actions;
};

export default function BookingActions({
  isBooking,
  fetchData,
  data = {},
  serviceId,
}) {
  const {
    _id: bookingId,
    jobFrequency,
    providerJobId,
    status,
    customerId,
    balAmount,
    bookingServiceConfig,
    estimateStatus,
    isEstimateSubmitted,
  } = data;
  const { isServiceEstimateApplicable } = bookingServiceConfig || {};
  const [suspendModal, setSuspendModal] = useState(false);
  const [assignModalData, setAssignModalData] = useState(null);
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const [otpModalType, setOtpModal] = useState(false);
  const [estimateModal, setEstimateModal] = useState(null);
  const [createPaymentModal, setCreatePaymentModal] = useState(false);
  const navigate = useNavigate();

  // Hide estimate after accept
  const hideEstimateAction =
    !isServiceEstimateApplicable ||
    (!!isEstimateSubmitted &&
      (estimateStatus === ESTIMATE_STATUSES.SUBMIT ||
        estimateStatus === ESTIMATE_STATUSES.ACCEPT));

  const closePaymentModal = () => setCreatePaymentModal(false);
  const closeOtpModal = () => setOtpModal(false);
  const closeEstimateModal = () => setEstimateModal(null);

  const onOtpSuccess = () => {
    fetchData();
    closeOtpModal();
  };

  const onEstimateSuccess = () => {
    fetchData();
    closeEstimateModal();
  };

  const onPaymentSuccess = () => {
    fetchData();
    closePaymentModal();
  };

  const actions = useMemo(
    () =>
      getBookingActions({
        setOtpModal,
        setAssignModalData,
        providerJobId,
        bookingId,
        jobFrequency,
        serviceId,
        fetchData,
        isBooking,
        hideEstimateAction,
        estimateStatus,
        setEstimateModal,
        setConfirmationModalData,
        setCreatePaymentModal,
        setSuspendModal,
        bookingStatus: status, // BOOKING_STATUS.booked.label,
        navigate,
      }),
    [
      jobFrequency,
      bookingId,
      providerJobId,
      isBooking,
      fetchData,
      status,
      serviceId,
      hideEstimateAction,
      estimateStatus,
      navigate,
    ]
  );

  if (!actions.length) {
    return null;
  }

  const closeAssignModal = () => setAssignModalData(null);
  const closeModal = () => setConfirmationModalData(null);
  return (
    <Card sx={{ position: "sticky", mt: "12px", px: 2 }}>
      <Header buttons={actions} />
      {!!assignModalData && (
        <AssignModal
          fetchData={fetchData}
          isBooking={isBooking}
          bookingData={data}
          closeModal={closeAssignModal}
          modalData={assignModalData}
        />
      )}
      {!!confirmationModalData && (
        <ConfirmationModal
          modalData={confirmationModalData}
          closeModal={closeModal}
        />
      )}
      {!!otpModalType && (
        <JobOtpModal
          type={otpModalType}
          onSuccessCallback={onOtpSuccess}
          closeModal={closeOtpModal}
          providerJobId={providerJobId}
        />
      )}
      {!!estimateModal && (
        <EstimateModal
          onSuccessCallback={onEstimateSuccess}
          closeModal={closeEstimateModal}
          data={data}
          chargesUpdatedFor={estimateModal.chargesUpdatedFor}
        />
      )}

      {!!createPaymentModal && (
        <AddPaymentModal
          data={{
            customerId: customerId,
            accountType: "customer",
            bookingId,
            bookingStatus: status,
            balAmount,
          }}
          isCustomer={true}
          onSuccessCallback={onPaymentSuccess}
          closeModal={closePaymentModal}
        />
      )}

      {!!suspendModal && (
        <SuspendBookingModal
          bookingId={bookingId}
          onClose={() => setSuspendModal(false)}
          onSuccessCallback={fetchData}
          serviceId={serviceId}
        />
      )}
    </Card>
  );
}
