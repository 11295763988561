export const PAGE_TYPE_CONSTANTS = {
  TERMS: "terms-conditions",
  FAQ: "faq",
  PRIVACY: "privacy-policy",
  ABOUT: "about-us",
};

export const PAGE_TYPES = [
  {
    type: "terms-conditions",
    title: "Terms And Conditions",
  },
  {
    type: "faq",
    title: "FAQ's",
  },
  {
    type: "privacy-policy",
    title: "Privacy Policy",
  },
  {
    type: "about-us",
    title: "About Us",
  },
];
